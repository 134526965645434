<template>
    <div :class="`col q-px-md q-py-sm bg-${$q.dark.isActive ? 'dark' : 'white'}`">
        <div class="row q-mb-sm">
            <div class="col-12 text-subtitle1 text-weight-medium text-blue-grey-4">
                {{ titulo }}
            </div>
        </div>
        <hr v-if="titulo">
        <slot />
    </div>
</template>

<script>

export default {
    name: 'QPanel',

    props: {
        titulo: {
            type: String,
            default: ''
        }
    },

    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>

</style>
