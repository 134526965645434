<template>
    <q-modal-view
        id="modNovoPagamento"
        ref="modal"
        title="Pagamento por Boleto"
        block-dismiss
        :disable-dismiss="boletoGerado"
    >
        <div class="col">
            <q-detalhes-boleto
                class="q-my-sm q-py-sm"
                :valor-total="valorTotal"
            />
            <div
                v-if="!boletoGerado"
                class="col q-mt-sm"
                style="margin-bottom: 70px;"
            >
                <q-panel titulo="ATENÇÃO">
                    <span v-html="mensagemBoleto" />
                </q-panel>
            </div>
            <div v-if="boletoGerado">
                <q-boleto-registrado
                    v-if="registrado"
                    class="q-mt-sm"
                    style="margin-bottom: 70px;"
                    :linha-digitavel="linhaDigitavel"
                    :url-boleto="urlBoleto"
                    :id-transacao="idTransacao"
                />
                <q-boleto-emitido
                    v-else
                    class="q-mt-sm"
                    :id-transacao="idTransacao"
                />
            </div>
            <div
                v-if="!boletoGerado"
                class="col"
            >
                <footer class="fixed-bottom row">
                    <q-btn
                        color="green"
                        label="Gerar Boleto"
                        class="full-width q-mt-sm"
                        style="height: 50px;"
                        @click="criarOrdemPagamento"
                    />
                </footer>
            </div>
        </div>
    </q-modal-view>
</template>
<script>

import QModalView from '@/components/shared/QModalView'
import QDetalhesBoleto from '@/components/Boleto/detalhesBoleto'
import QBoletoRegistrado from '@/components/Boleto/boletoRegistrado'
import QBoletoEmitido from '@/components/Boleto/boletoEmitido'
import QPanel from '@/components/shared/QPanel'

import MixinMessages from '@/mixins/mixinMessages'

import OrdemPagamentoService from '@/services/ordens_pagamento'
import ParametrosServices from '@/services/parametros'

export default {
    name: 'QModalBoletos',

    components: {
        QDetalhesBoleto,
        QBoletoEmitido,
        QBoletoRegistrado,
        QModalView,
        QPanel
    },

    mixins: [
        MixinMessages
    ],

    props: {
        idCliente: {
            type: String,
            default: null
        },

        idDependente: {
            type: String,
            default: null
        },

        parcelas: {
            type: Array,
            default: null
        }
    },

    data() {
        return {
            parcelaItens: [],
            idTransacao: null,
            situacao: 0,
            boletoGerado: false,
            linhaDigitavel: null,
            urlBoleto: null,
            mensagemBoleto: null
        }
    },

    computed: {
        valorTotal() {
            let valor = 0
            this.parcelas.map(el => (
                valor = valor + el.valor_total
            ))
            return valor.toFixed(2).replace('.', ',')
        },

        registrado() {
            return this.situacao === 2
        }
    },

    watch: {
        parcelas: function(value) {
            this.$_carregaItensParcelas(value)
        }
    },

    created() {
        this.$_buscaMensagemBoleto()
    },

    methods: {
        $_carregaItensParcelas(parcelas) {
            this.parcelaItens = []
            parcelas.map(el => (
                this.parcelaItens.push({
                    id_parcela: el.id,
                    valor: el.valor_parcela,
                    desconto: el.valor_desconto,
                    juro: el.valor_juro
                })
            ))
        },

        criarOrdemPagamento() {
            this.showLoading({ message: 'Gerando Boleto, Aguarde!' })
            OrdemPagamentoService.post({
                id_cliente: this.idCliente,
                id_dependente: this.idDependente,
                valor_total: parseFloat(this.valorTotal.replace(',', '.')),
                id_loja: process.env.VUE_APP_ID_LOJA_ECOMMERCE,
                itens: [...this.parcelaItens],
                transacoes: [{
                    tipo: 'Boleto',
                    gateway: 'TecnoSpeed',
                    valor: parseFloat(this.valorTotal.replace(',', '.'))
                }]
            }).then(({ data }) => {
                this.buscaOrdemPagamento(data.id)
            }).catch(error => {
                this.hideLoading()
                let mensagemErro
                try {
                    mensagemErro = error.response.data.datas.itens[0].general[0]
                } catch {
                    mensagemErro = 'O pagamento desta prestação ou das anteriores podem estar sendo processados'
                }
                this.errorMessage({
                    title: 'Ops, Algo de errado aconteceu!',
                    message: 'Ocorreu um erro ao gerar o boleto',
                    e: mensagemErro
                })
            })
        },

        buscaOrdemPagamento(id) {
            OrdemPagamentoService.getOrdemPagamentoId(id)
                .then(({ data }) => {
                    this.hideLoading()
                    this.idTransacao = data.transacoes[0].id
                    this.linhaDigitavel = data.transacoes[0].boleto.linha_digitavel
                    this.urlBoleto = data.transacoes[0].boleto.url_boleto
                    this.situacao = data.transacoes[0].boleto.situacao
                    this.boletoGerado = true
                })
                .catch((e) => {
                    this.errorMessage({
                        title: 'Ops, Algo de errado aconteceu!',
                        message: 'Ocorreu um erro ao gerar o boleto',
                        e: e
                    })
                })
        },

        $_buscaMensagemBoleto() {
            ParametrosServices.getParametro('BOLETO.MENSAGEM_APP')
                .then(({ data }) => {
                    this.mensagemBoleto = String(data[0].valor)
                })
                .catch(e => this.errorMessage({
                    title: 'Ops, Atenção de errado aconteceu!',
                    message: 'Ocorreu um erro o buscar a mensagem do Boleto.',
                    error: e
                })
                )
        },

        open() {
            this.$refs.modal.show()
        }
    }
}
</script>

<style>

</style>
