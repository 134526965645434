<template>
    <div class="col q-mt-md">
        <div class="col">
            <q-input
                filled
                v-model="email"
                label="E-mail"
                type="email"
                :disable="naoPossuiEmail"
            />
        </div>

        <q-checkbox
            class="q-mt-sm"
            v-model="naoPossuiEmail"
            label="Não possuo e-mail."
        />

        <div
            v-if="naoPossuiEmail"
            class="col q-mt-sm q-mb-sm"
        >
            <q-input
                filled
                v-model="telefone"
                label="Telefone"
                type="tel"
                mask="(##) # #### - ####"
            />
        </div>

        <footer class="fixed-bottom row">
            <div class="col q-mt-md">
                <q-btn
                    color="green"
                    label="Finalizar"
                    class="full-width q-top-space"
                    style="height: 50px;"
                    @click="finalizar"
                />
            </div>
        </footer>
    </div>
</template>

<script>

import TransacaoService from '@/services/transacao'
import MixinMessages from '@/mixins/mixinMessages'

export default {
    name: 'QContato',

    mixins: [
        MixinMessages
    ],

    props: {
        idTransacao: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            telefone: '',
            email: '',
            naoPossuiEmail: false
        }
    },

    watch: {
        naoPossuiEmail: function(value) {
            value ? this.email = '' : this.telefone = ''
        }
    },

    methods: {
        finalizar() {
            if (this.telefone === '' && this.email === '') {
                this.warningMessage({
                    title: 'Atenção',
                    message: `Preencha o ${this.naoPossuiEmail ? 'telefone' : 'e-mail'} para imprimir a segunda via.`
                })
            } else {
                if (this.email && !this.$_validateEmail(this.email)) {
                    this.warningMessage({
                        title: 'Atenção',
                        message: 'Preencha um e-mail válido'
                    })
                } else if (this.telefone && this.telefone.replace(/[().\s-]+/g, '').length < 11) {
                    this.warningMessage({
                        title: 'Atenção',
                        message: 'Preencha um telefone válido'
                    })
                } else {
                    this.$_atualizaContatoBoleto()
                }
            }
        },

        $_atualizaContatoBoleto() {
            this.showLoading({ message: 'Salvando informações de contato, Aguarde!' })
            TransacaoService.patch(this.idTransacao, {
                boleto: {
                    sacado_celular: this.telefone ? this.telefone.replace(/[().\s-]+/g, '') : null,
                    sacado_email: this.email ? this.email : null
                }
            }).then(() => {
                this.hideLoading()
                this.successMessage({
                    title: 'Pagamento solicitado!',
                    message: 'Você pode consultar o andamento através da aba segunda via.'
                })
                this.$router.push('/home')
            }).catch(e => {
                this.hideLoading()
                this.errorMessage({
                    title: 'Ops, Algo de errado aconteceu!',
                    message: 'Erro ao salvar informações de contato.',
                    error: e
                })
            })
        },

        $_validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return re.test(String(email).toLowerCase())
        }
    }
}
</script>

<style>

</style>
