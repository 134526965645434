import ServicesAuth from './servicesAuth'

class OrdensPagamentService extends ServicesAuth {
    constructor() {
        super({
            url: '/ordenspagamento',
            config: {
                baseURL: process.env.VUE_APP_API_CREDITO_URL,
                headers: {
                    authorization: `JWT ${process.env.VUE_APP_API_CREDITO_TOKEN}`
                },
                timeout: 60 * 1000
            }
        })
    }

    getOrdemPagamentoId(id){
        return this.getByID(id)
    }

    getOrdensPagamentoProcessamento(idCliente, idDependente) {
        if (idDependente) {
            return this.get({ status_pagamento: 0, id_cliente: idCliente, id_dependente: idDependente, sort: '-data_emissao' })
        }
        return this.get({ status_pagamento: 0, id_cliente: idCliente, sort: '-data_emissao' })
    }

    getOrdensPagamentoEfetuados(idCliente, idDependente, todos = true) {
        if (todos) {
            return this.get({ status_pagamento__not_equal: 0, id_cliente: idCliente, sort: '-data_emissao' })
        }
        if (idDependente) {
            return this.get({ status_pagamento__not_equal: 0, id_cliente: idCliente, id_dependente: idDependente, sort: '-data_emissao' })
        }
        return this.get({ status_pagamento__not_equal: 0, id_cliente: idCliente, carne__id_dependente__isnull: 'True', sort: '-data_emissao' })
    }
}

export default new OrdensPagamentService()
