<template>
    <q-panel titulo="BOLETO GERADO">
        <div class="col q-mt-md">
            <div class="col">
                <div class="col">
                    <span class="text-subtitle2 text-weight-bold text-blue-grey-4">
                        Linha digitável
                    </span>
                    <br>
                    <span class="text-subtitle2 q-mt-md">
                        <div class="center">
                            {{ linhaDigitavel }}
                        </div>
                    </span>
                    <br>
                </div>

                <div class="col">
                    <q-btn
                        class="full-width"
                        style="height: 50px;"
                        label="Copiar Linha Digitável"
                        color="primary"
                        v-clipboard:copy="linhaDigitavel"
                        v-clipboard:success="$_successCopyLinhaDigitavel"
                        v-clipboard:error="$_errorCopyLinhaDigitavel"
                    />
                </div>

                <div class="col q-mt-md">
                    <q-btn
                        class="full-width"
                        style="height: 50px;"
                        label="Visualizar Boleto"
                        color="primary"
                        @click="$_visualizarBoleto"
                        v-if="!$store.getters.getModoTotem"
                    />
                </div>

                <q-separator class="q-mt-md" />

                <div class="col q-mt-sm">
                    <q-checkbox
                        v-model="notificar"
                        label="Desejo receber informações sobre o boleto (Opcional)"
                    />

                    <div
                        v-if="notificar"
                        class="col q-mt-md"
                    >
                        <q-contato
                            :id-transacao="idTransacao"
                        />
                    </div>
                </div>

                <div
                    v-if="!notificar"
                    class="col"
                >
                    <footer class="fixed-bottom row">
                        <q-btn
                            color="green"
                            label="Finalizar"
                            class="full-width q-top-space"
                            style="height: 50px;"
                            @click="$_finalizar"
                        />
                    </footer>
                </div>
            </div>
        </div>
    </q-panel>
</template>

<script>

import QPanel from '@/components/shared/QPanel'
import QContato from './contato'
import MixinMessages from '@/mixins/mixinMessages'

export default {
    name: 'QBoletoRegistrado',

    components: {
        QPanel,
        QContato
    },

    mixins: [
        MixinMessages
    ],

    props: {
        linhaDigitavel: {
            type: String,
            default: null
        },

        urlBoleto: {
            type: String,
            default: null
        },

        idTransacao: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            notificar: false
        }
    },

    methods: {
        $_finalizar() {
            this.$router.push('/home')
        },

        $_visualizarBoleto() {
            window.open(this.urlBoleto)
        },

        $_successCopyLinhaDigitavel() {
            this.successMessage({
                title: 'Boleto',
                message: 'Linha digitável Copiado!'
            })
        },

        $_errorCopyLinhaDigitavel() {
            this.errorMessage({
                title: 'Ops, Algo de errado aconteceu!',
                message: 'Não foi possível copiar a linha digitável!'
            })
        }
    }
}
</script>

<style>

</style>
