import ServicesAuth from './servicesAuth'

class TransacaoServices extends ServicesAuth {
    constructor() {
        super({
            url: '/transacoes',
            config: {
                baseURL: process.env.VUE_APP_API_CREDITO_URL,
                headers: {
                    Authorization: `JWT ${process.env.VUE_APP_API_CREDITO_TOKEN}`
                },
                timeout: 60 * 1000
            }
        })
    }
}

export default new TransacaoServices()
