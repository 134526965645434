<template>
    <div class="col">
        <q-panel titulo="DETALHES">
            <div class="row q-mt-sm">
                <div class="col">
                    <span class="text-subtitle2 text-weight-bold text-blue-grey-4">
                        Valor Total
                    </span>
                    <br>
                    <span class="text-subtitle2">
                        R$ {{ valorTotal }}
                    </span>
                    <br>
                </div>

                <div class="col">
                    <span class="text-subtitle2 text-weight-bold text-blue-grey-4">
                        Data de Vencimento
                    </span>
                    <br>
                    <span class="text-subtitle2">
                        {{ dataVencimento }}
                    </span>
                </div>
            </div>
        </q-panel>
    </div>
</template>

<script>

import { date } from 'quasar'

import QPanel from '@/components/shared/QPanel'

export default {
    name: 'QDetalhesBoleto',

    components: {
        QPanel
    },

    props: {
        valorTotal: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            dataVencimento: date.formatDate(date.addToDate(new Date(), { days: Number(process.env.VUE_APP_DIAS_VENCIMENTO_BOLETO) }), 'DD/MM/YYYY')
        }
    }
}
</script>

<style>

</style>
