<template>
    <div class="col">
        <q-panel titulo="EM PROCESSAMENTO">
            <div class="col text-subtitle1 text-weight-medium">
                Estamos gerando o seu boleto, informe-nos como você deseja recebê-lo
            </div>
            <q-contato
                :id-transacao="idTransacao"
            />
        </q-panel>
    </div>
</template>

<script>

import QPanel from '@/components/shared/QPanel'
import QContato from './contato'

export default {
    name: 'QBoletoEmitido',

    components: {
        QPanel,
        QContato
    },

    props: {
        idTransacao: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            email: '',
            telefone: ''
        }
    }
}
</script>

<style>

</style>
